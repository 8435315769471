import Cookies from 'js-cookie';
import {
    nameCartId, expiredDefault, cookiesSellerPage, cookiesSellerName,
} from '@config';

export const getCartId = () => {
    const cartId = Cookies.get(nameCartId);
    return cartId;
};

export const setCartId = (token, expired) => {
    Cookies.set(nameCartId, token, { expires: expired || expiredDefault });
    return true;
};

export const removeCartId = () => {
    Cookies.remove(nameCartId);
    return true;
};

export const setLastSellerVisit = (value, id) => {
    Cookies.set('last_seller_visited', value);
    Cookies.set('last_seller_visited_id', id);
};

export const getLastSellerVisit = () => Cookies.get('last_seller_visited');
export const getLastSellerIdVisit = () => Cookies.get('last_seller_visited_id');

export const getSellerPage = () => Cookies.get(cookiesSellerPage);
export const getSellerName = () => Cookies.get(cookiesSellerName);

export const removeLastSellerVisit = () => {
    Cookies.remove('last_seller_visited');
    Cookies.remove('last_seller_visited_id');
};
